import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryButton } from "@moneris-portal/button";
import applicationDescription from "./constant";
import createTranslator from "../../../util/translationHelper";
import messages from "./translations/messages";
import { useMsal } from "@azure/msal-react";
// import { userActions } from "../../../contextProviders/constant";
import "./index.scss";

export default function ApplicationCard(props) {
  const {
    applicationObject,
    token,
    handleAuditLog,
    isAutoRedirect = true,
    handleAppLaunch = () => null,
    handleActivateTile,
  } = props;
  const {
    app_title,
    app_logo,
    login_endpoint,
    status = "Active",
    app_description,
    app_code = "",
    is_activated = true,
  } = applicationObject;
  const translate = createTranslator(messages);
  const { instance } = useMsal();

  const handleApplicationCardClick = () => {
    if (app_code?.toString()?.toLowerCase() === "idi") {
      window.open(`${login_endpoint}`, "_blank");
    } else {
      const accountList = instance.getAllAccounts() ?? [];
      if (token && accountList.length) {
        handleAuditLog({ appCode: app_code, success: true });
        window.open(`${login_endpoint}?token=${token}&sso=1`, "_blank");
      } else {
        console.log("no user signed in");
        window.location.reload();
      }
    }
  };

  const handleActivateApp = (appCode) => {
    handleActivateTile(appCode);
  };

  const handleMiddlewareLaunch = () => {
    const lang = window.localStorage?.getItem("lang") ?? "en";
    window.sessionStorage.setItem("activeSession", true);
    window.localStorage.setItem("tempMgoRedirectUrl", login_endpoint);
    window.open(
      `${window.location.origin}/${lang}/home?app_code=${app_code}`,
      "_blank"
    );
  };

  const appDescription = () => {
    let appInfo = app_description;
    if (
      applicationDescription.hasOwnProperty(app_code?.toString()?.toLowerCase())
    ) {
      const { desc = "", list = [] } =
        applicationDescription[app_code?.toString()?.toLowerCase()] ?? {};
      appInfo = (
        <div className="app_description">
          <p>{translate(desc)}</p>
          <p>
            <ul>
              {list.map((item) => (
                <li>{translate(item)}</li>
              ))}
            </ul>
          </p>
        </div>
      );
    }
    return appInfo;
  };

  const handleUserGuide = () => {
    if (
      applicationDescription.hasOwnProperty(app_code?.toString()?.toLowerCase())
    ) {
      const { urlCode = "" } =
        applicationDescription[app_code?.toString()?.toLowerCase()] ?? {};
      const url = translate(urlCode);
      if (url) window.open(url, "_blank");
    }
  };

  return (
    <div
      className="application_card_container"
      disabled={status !== "Active" ? "disabled" : ""}
    >
      <div className="application_card_body">
        <div
          className={`cardIcon ${
            app_code?.toLowerCase() === "md2" ? "wide" : ""
          }`}
        >
          <img src={app_logo} alt="" />
        </div>
        <div className="card_desc">
          <div className="cardTitle">
            <span>{app_title}</span>
          </div>
          <div className="cardDesc">
            <span>{appDescription()}</span>
          </div>
          <div className="_card_buttons">
            <SecondaryButton
              title={translate("user_guide")}
              clickHandler={handleUserGuide}
            />
            <PrimaryButton
              title={is_activated ? translate("launch") : translate("activate")}
              clickHandler={() =>
                status === "Active"
                  ? !is_activated
                    ? handleActivateApp(app_code)
                    : isAutoRedirect
                    ? handleMiddlewareLaunch()
                    : handleApplicationCardClick()
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ApplicationCard.defaultProps = {
  applicationObject: {},
};

ApplicationCard.propTypes = {
  applicationObject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    appId: PropTypes.string.isRequired,
  }),
};
