/* eslint-disable */
import React, { useEffect, useState } from "react";
import createTranslator from "../../util/translationHelper";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "@moneris-portal/button";
import { useAppStateProvider } from "../../contextProviders/AppStateProvider";
import Loader from "@moneris-portal/loader";
import messages from "./Translations/messages";

import "./index.scss";

function SignUp({ lang }) {
  const [loading, resetLoading] = useState(true);
  const history = useHistory();
  const { instance, accounts } = useMsal();
  const homeAccountId = accounts[0]?.homeAccountId ?? "";
  const { appState = {}, updateAppStateLocal } = useAppStateProvider();
  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
  };

  useEffect(() => {
    setTimeout(() => {
      resetLoading(false);
    }, 3000);
  }, []);

  const handleHomeNavigation = () => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * 120;
    now.setTime(expireTime);
    const domain = /local/.test(window.location.host)
      ? "localhost"
      : "moneris.com";
    document.cookie = `action=signUp; expires=+${now.toUTCString()}+;domain=${domain};path=/`;
    instance.logoutRedirect(logoutRequest);
  };

  const translate = createTranslator(messages, lang);
  return (
    <div className="sign-up--container">
      {loading ? (
        <Loader lang={lang} />
      ) : (
        <div className="sign-up--body">
          <h2 class="text-center">{translate("sign_up_message")}</h2>
          <PrimaryButton
            title={translate("logout")}
            clickHandler={handleHomeNavigation}
          />
        </div>
      )}
    </div>
  );
}

SignUp.defaultProps = {
  // message: null
};

SignUp.propTypes = {
  // message: PropTypes.any
};

export default SignUp;
