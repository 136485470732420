/*eslint-disable*/
const security = {
  en: {
    user_profile: "User profile",
    user_id: "User ID",
    user_first_name: "First name",
    user_last_name: "Last name",
    user_phone_number: "User phone number",
    update: "Update",
    cancel: "Cancel",
    contact_phone_number: "Contact phone number",
    edit: "Edit",
    mobile_phone_number: "Mobile phone number",
    confirm_email: "Confirm email address",
    add_mobile_number: "Add mobile number",
    add_email: "Add Email",
    security: "Security",
    two_factor_authentication: "Two-factor authentication",
    enable_two_factor: "Enable two-factor authentication",
    methods: "Select 2FA method",
    authenticator: "Authenticator App",
    enable_tooltip:
      "Enable two-factor authentication to increase the security of your Moneris account.",
    set_authenticator_app: "Set-up Authenticator App",
    set_authenticator_app_line1: "Two-factor authentication is now enabled.",
    set_authenticator_app_line2:
      "Moneris recommends you set up an authenticator app. If you do not want to use an app, you can still authenticate using SMS or email.",
    set_authenticator_app_line3:
      "Would you like to set-up an authenticator app now?",
    yes: "Yes",
    not_now: "Not Now",
    authenticator_app_setup: "Authenticator App Setup",
    authenticator_app_setup_line1:
      "Two-factor authentication increases the security of your Moneris account. Setting up an authenticator app is the recommended method to authenticate. If you do not want to set-up an app, you can still authenticate using SMS or email.",
    step1: "Step 1 - Install Authenticator App",
    step1_text1: "Install an authenticator app compatible with your device.",
    step1_text2:
      "Suggested Authenticator Apps: Google, LastPass, Microsoft, Entrust, ...",
    step2: "Step 2 - Scan",
    step2_text1: "Scan the following QR Code with your authenticator app.",
    step2_text2:
      "If you cannot scan the QR Code, enter this code into your authenticator app.",
    step3: "Step 3 - Verify",
    step3_text1:
      "Enter the verification code generated by your authenticator app.",
    verify: "Verify",
    disable_two_factor: "Disable Two-Factor Authentication",
    disable_two_factor_text1:
      "Disabling two-factor authentication will remove the authenticator app currently linked to your user account.",
    disable_two_factor_text2:
      "Are you sure you want to disable two-factor authentication?",
    yes_disable: "Yes, Disable",
    no: "No",
    text_message: "Text message (SMS)",
    change_mobile: "Change Mobile Number",
    change_email: "Change Email",
    email: "Email",
    copy: "Copy",
    setup: "Set-up",
    remove_app: "Remove app",
    authenticator_app_tooltip:
      "Set-up an authenticator app on your mobile phone to authenticate your identity",
    unsaved_changes: "Unsaved Changes",
    unsaved_changes_body1: "You have unsaved changes.",
    unsaved_changes_body2: "How would you like to proceed?",
    continue_editing: "Continue editing",
    discard: "Discard",
    password: "Password",
    change: "Change password",
    last_change: "Last changed",
    date_expiry: "Date of expiry",
    date_expiry_tooltip: "Your password will expire every 90 days",
    update_password: "Update Password",
    create_new_password: "Create a new password.",
    current_password: "Current password",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    characters: "10 or more characters",
    upper_characters: "Upper & lower case characters",
    number: "At least one number",
    password_match: "New passwords match",
    note: "Note:",
    last_four: "You cannot reuse one of your last four passwords.",
    submit: "Submit",
    empty_check1: "Current password cannot be empty.",
    error_invalid_code: "Invalid code, try again.",
    error_something_wrong: "Something went wrong, try again.",
    error_valid_email: "Enter a valid email address.",
    error_valid_phone: "Enter a valid 10 digit phone number.",
    yes_remove: "Yes, Remove",
    error_phone_taken: "Phone has already been taken, try again.",
    error_phone_update: "Error updating phone.",
    sent_code: "We have sent a code to",
    verify_mobile: "Verify Mobile Phone Number",
    verify_email: "Verify Email Address",
    error_unable_qrcode: "Unable to retrive the QR code and security key.",
    error_code_match: "Code doesn’t match our files, try again.",
    remove_authenticator:
      "Are you sure you want to remove the authenticator app linked to your user account?",
    remove_authenticator_app: "Remove Authenticator App",
    success_update: "successfully updated.",
    success_update_profile: "successfully updated.",
    error_updating: "Error updating",
    error_updating_profile: "Error updating",
    error_code_exipred: "Your verification code has expired, try again.",
    code_update_profile: "Enter the code below to update your profile.",
    code_expires: "The code expires in 10 minutes.",
    error_email_match: "Email addresses do not match.",
    error_email_taken: "Email address has already been taken, try again.",
    error_email_update: "Error updating email address.",
    phone_number: "phone number",
    days_remaining: "days remaining",
    error_two_factor_authentication: "Two-factor authentication",
    error_email: "email",
    profile: "profile",
    error_password: "password",
    ext: "Ext.",
    email_address: "Email address",
    error_first_name: "First name cannot be empty.",
    error_name_format: "Digits and special characters are not valid.",
    error_last_name: "Last name cannot be empty.",
    error_phone_number: "Phone number cannot be empty.",
    error_ext: "Enter valid Ext.",
    phone_placeholder: "eg. 999-999-9999",
    ext_placeholder: "eg. 123",
    email_placeholder: "eg. email@example.com",
    email_notification: "Email notifications",
    dispute_notification_tooltip:
      "Notify me when I receive cardholder disputes that require my attention.",
    notification_email_address: "Notification email address",
    preferred_lang: "Notification language",
    crd_dispute_notification: "Cardholder dispute notifications",
    chargeback: "Chargeback(s)",
    self_serve_banking_eligibility_success_msg:
      "Update bank account successfully update",
    self_serve_banking_eligibility_failure_msg:
      "Unable to update update bank account",
    copy_rqst: "Copy request(s) ",
    refund_success_msg: "Process refund successfully update",
    refund_failure_msg: "Unable to update Process refund",
    self_serve_contact_update_success_msg:
      "Update profile successfully updated",
    self_serve_contact_update_failure_msg: "Unable to update update profile",
    tooltip_message_info_radiobutton_phone:
      "You must click the set-up button before 2FA can be enabled",
    tooltip_message_info_radiobutton_2fa:
      "You must click the set-up button before 2FA can be enabled",

    totp_failure_msg: "Unable to remove the authenticator app, try again.",
    totp_successful_msg: "The authenticator app has been successfully removed",

    two_fa_setup: "Two Factor Authenticator App Set-up",
    verify_code: "Verify Code",
    mobile_two_fa_setup: "Mobile Two Factor Authentication Set-up",
    use_your_mobile_phone_number:
      "Use your mobile phone number to verify this device.",
    verification_code: "Verification Code",
    copy_the_verification_code:
      "Copy the verification code into the input box below.",
    sec_question_title: "Security questions",
    sec_question_label: "Security questions",
    sec_question_button_label: "Change security questions",
    sec_question:"Security questions"
  },

  fr: {
    user_profile: "Profil de l'utilisateur",
    user_id: "ID d'utilisateur",
    user_first_name: "Prénom",
    user_last_name: "Nom de famille",
    user_phone_number: "Numéro de téléphone de l’utilisateur",
    update: "Mettre à jour",
    cancel: "Annuler",
    contact_phone_number: "Numéro de téléphone",
    ext: "Poste",
    edit: "Modifier",
    mobile_phone_number: "Numéro de téléphone cellulaire",
    confirm_email: "Confirmation de l’adresse courriel de l’utilisateur",
    add_mobile_number: "Ajouter un numéro de téléphone cellulaire",
    add_email: "Ajoutez le courriel",
    security: "Sécurité",
    two_factor_authentication: "Authentification à deux facteurs",
    enable_two_factor: "Activer l'authentification à deux facteurs",
    methods: "Sélectionnez la méthode 2FA",
    authenticator: "de l’application d’authentification",
    enable_tooltip:
      "Activez l'authentification à deux facteurs pour augmenter la sécurité de votre compte Moneris.",
    set_authenticator_app: "Configurer l'application Authenticator",
    set_authenticator_app_line1:
      "L'authentification à deux facteurs est activée.",
    set_authenticator_app_line2:
      "Moneris vous recommande de configurer une application d'authentification. Si vous ne souhaitez pas utiliser une application, vous pouvez  vous authentifier par SMS ou par courriel.",
    set_authenticator_app_line3:
      "Voulez-vous configurer une application d'authentification maintenant?",
    yes: "Oui",
    not_now: "Non, pas maintenant",
    authenticator_app_setup:
      "Configuration de l'application d'authentification",
    authenticator_app_setup_line1:
      "L'authentification à deux facteurs augmente la sécurité de votre compte Moneris. La configuration d'une application d'authentification est la méthode recommandée pour s'authentifier. Si vous ne souhaitez pas configurer d'application, vous pouvez toujours vous authentifier par SMS ou par courriel.",
    step1: "Étape 1 - Installation",
    step1_text1:
      "Installez une application d'authentification compatible avec votre appareil.",
    step1_text2:
      "Applications d'authentification recommandées : Google, LastPass, Microsoft, Entrust, etc.",
    step2: "Étape 2 - Scannez",
    step2_text1:
      "Scannez le code QR suivant avec votre application d'authentification.",
    step2_text2:
      "Si vous ne pouvez pas scanner le code QR, entrez ce code dans votre application d'authentification.",
    step3: "Étape 3 - Vérification",
    step3_text1:
      "Entrez le code de vérification généré par votre application d'authentification.",
    verify: "Vérifier",
    disable_two_factor: "Désactivation",
    disable_two_factor_text1:
      "La désactivation de l'authentification à deux facteurs supprimera l'application d'authentification qui est actuellement liée à votre compte d'utilisateur.",
    disable_two_factor_text2:
      "Souhaitez-vous vraiment désactiver l'authentification à deux facteurs?",
    yes_disable: "Oui, désactiver",
    no: "Non",
    text_message: "Message texte (SMS)",
    change_mobile: "Changer le numéro de téléphone cellulaire",
    change_email: "Changer l'adresse courriel",
    email: "Courriel",
    copy: "Copier",
    setup: "Configuration",
    remove_app: "Supprimer l’application",
    authenticator_app_tooltip:
      "Configurez une application d’authentification sur votre téléphone cellulaire pour confirmer votre identité.",
    unsaved_changes: "Changements non sauvegardés",
    unsaved_changes_body1:
      "Vous avez apporté des changements qui ne seront pas sauvegardés.",
    unsaved_changes_body2: "Comment souhaitez-vous procéder?",
    continue_editing: "Continuer à modifier",
    discard: "Ignorer les changements",
    password: "Mot de passe",
    change: "Modifier mot de passe",
    last_change: "Dernier changement",
    date_expiry: "Date d'expiration",
    date_expiry_tooltip:
      "Vous devrez modifier votre mot de passe tous les 90 jours.",
    update_password: "Modification du mot de passe ",
    create_new_password: "Créez un nouveau mot de passe.",
    current_password: "Mot de passe actuel",
    new_password: "Nouveau mot de passe",
    confirm_new_password: "Confirmation du mot de passe",
    characters: "10 caractères ou plus",
    upper_characters: "Lettre majuscule et lettre minuscule",
    number: "Au moins un numéro",
    password_match: "Les nouveaux mots de passe sont identiques",
    note: "Remarque : ",
    last_four:
      "Vous ne pouvez pas réutiliser l'un de vos quatre derniers mots de passe.",
    submit: "Soumettre",
    empty_check1: "Le champ du mot de passe actuel ne peut pas être vide.",
    error_invalid_code: "Code non valide. Veuillez réessayer.",
    error_something_wrong:
      "Une erreur c'est produit. Veuillez essayer de nouveau.",
    error_valid_email: "Veuillez entrer un courriel valide.",
    yes_remove: "Oui, supprimer",
    error_phone_taken: "Le téléphone a déjà été pris, essayez à nouveau.",
    error_phone_update: "Erreur de mise à jour du téléphone.",
    sent_code: "Nous avons envoyé un code à",
    verify_mobile: "Vérifier le numéro de téléphone mobile",
    verify_email: "Vérifier adresse de courriel",
    error_unable_qrcode:
      "Impossible de récupérer le code QR et la clé de sécurité.",
    error_code_match:
      "Le code ne correspond pas à celui dans nos dossiers. Veuillez réessayer.",
    remove_authenticator:
      "Êtes-vous sûr de vouloir supprimer l’application d’authentification liée à votre compte utilisateur ?",
    remove_authenticator_app: "Supprimer l’application Authenticator",
    success_update: "a été mise à jour avec succès.",
    success_update_profile: "a été mis à jour avec succès.",
    error_updating: "Une erreur s’est produite lors de la mise à jour",
    error_updating_profile: "Une erreur s’est produite lors de la mis à jour",
    error_code_exipred: "Votre code de vérification a expiré, réessayez.",
    code_update_profile:
      "Entrez le code ci-dessous pour mettre à jour votre profil.",
    code_expires: "Le code expire dans 10 minutes.",
    error_email_match:
      "L’adresse courriel ne correspond pas à celle que vous avez entrée plus haut.",
    error_email_taken:
      "Cette adresse courriel a déjà été utilisée. Veuillez réessayer.",
    error_email_update:
      "Erreur lors de la mise à jour de l'adresse de courriel.",
    phone_number: "le numéro de téléphone",
    days_remaining: "jours restants",
    error_two_factor_authentication: "authentification à deux facteurs",
    error_email: "l'adresse courriel",
    profile: "le profil de l’utilisateur",
    error_password: "mot de passe",
    email_address: "Adresse courriel",
    error_first_name: "Le champ du prénom ne peut pas être vide.",
    error_name_format:
      "Les chiffres et les caractères spéciaux ne sont pas valides",
    error_last_name: "Le champ du nom de famille ne peut pas être vide.",
    error_phone_number:
      "Le champ du numéro de téléphone ne peut pas être vide.",
    error_valid_phone: "Veuillez entrer un numéro de téléphone à 10 chiffres.",
    error_ext: "Entrez un poste valide.",
    phone_placeholder: "p. ex. 999 999-9999",
    ext_placeholder: "p. ex. 123",
    email_placeholder: "p. ex. courriel@exemple.com",
    email_notification: "Notifications par courriel",
    notification_email_address: "Adresse courriel pour les notifications",
    dispute_notification_tooltip:
      "Informez-moi lorsque je reçois des différends du titulaire de carte qui nécessitent mon attention.",
    preferred_lang: "Langue des notifications",
    crd_dispute_notification: `Avis de différend du titulaire de carte`,
    chargeback: `Débit(s) compensatoire(s)`,
    self_serve_banking_eligibility_success_msg:
      "Mise à jour du compte bancaire réussie",
    self_serve_banking_eligibility_failure_msg:
      "Impossible de mettre à jour le compte bancaire",
    copy_rqst: "Demande(s) de documentation",
    refund_success_msg: "Processus de remboursement mis à jour avec succès",
    refund_failure_msg:
      "Impossible de mettre à jour le processus du remboursement",
    self_serve_contact_update_success_msg:
      "Mettre à jour le profil mis à jour avec succès",
    self_serve_contact_update_failure_msg:
      "Impossible de mettre à jour le profil de mise à jour",
    tooltip_message_info_radiobutton_phone:
      "Vous devez cliquer sur le bouton modifier avant d'activer la fonction authentification à deux facteurs",
    tooltip_message_info_radiobutton_2fa:
      "Vous devez cliquer sur le bouton de configuration avant que l'option authentification à deux facteurs puisse être être activée.",

    totp_failure_msg: "Unable to remove the authenticator app, try again.",
    totp_successful_msg: "The authenticator app has been successfully removed",

    two_fa_setup: "Configuration de l'application Two Factor Authenticator",
    verify_code: "Vérifier le code",
    mobile_two_fa_setup:
      "Configuration de l'authentification mobile à deux facteurs",
    use_your_mobile_phone_number:
      "Utilisez votre numéro de téléphone mobile pour vérifier cet appareil.",
    verification_code: "Code de vérification",
    copy_the_verification_code:
      "Copiez le code de vérification dans le champ ci-dessous.",
    sec_question_title: "Questions de sécurité",
    sec_question_label: "Questions de sécurité",
    sec_question_button_label: "Changer les questions de sécurité",
     sec_question:"Questions de sécurité"
  },
};

export default security;
