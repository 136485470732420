/*eslint-disable*/
const error = {
  en: {
    sign_up_message: 'Please Logout before creating a new account.',
    logout: 'Logout'
  },

  fr: {
    sign_up_message: 'Veuillez vous déconnecter avant de créer un nouveau compte.',
    logout: 'Déconnexion'
  }
};

export default error;
